<template>
  <div>
    <div class="d-flex mx-3">
      <div v-if="register">
        <label class="m-0 p-0">
          <slot name="default"/> 
        </label>
        <div class="d-flex justify-content-center mt-50">
          <b-form-checkbox switch v-model="register.value" value="true" unchecked-value="false" />
        </div>
      </div>
      <div v-else>
        <b-skeleton height="15px" width="85px"/>
        <div class="d-flex justify-content-center mt-50">
          <span class="round overflow-hidden">
            <b-skeleton height="25px" width="45px" class="m-0"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
   BFormInput, 
   BFormGroup, 
   BForm, 
   BBadge, 
   BModal,
   BFormCheckbox,
   BSkeleton,
} from 'bootstrap-vue'

import HelperTooltip from "@/layouts/components/HelperTooltip.vue";

  export default {
    components: {
      BFormInput, 
      BFormGroup, 
      BForm, 
      BBadge, 
      BModal, 
      BFormCheckbox,
      HelperTooltip,
      BSkeleton
    },
    data() {
      return {
      }
    },
    props: {
      value: {
        type: Object,
        required: true 
      },
    },
    computed: {
      register: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      name() {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>